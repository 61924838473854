@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

:root {
  --bg-color: #282a36;
  --text-color: #f8f8f2;
  --link-color: #ff79c6;
  --project-bg: #44475a;
  --project-border: #6272a4;
  --time-color: #bd93f9;
  --pulse-color: rgba(255, 0, 0, 0.7);
}

.light-mode {
  --bg-color: linear-gradient(to bottom right, #f5e6d3, #fff5e6);
  --text-color: #333;
  --link-color: #FF5555;
  --project-bg: #fff;
  --project-border: #ddd;
  --time-color: #666;
  --pulse-color: rgba(0, 0, 0, 0.7);
}

body {
  font-family: Arial, sans-serif;
  background: var(--bg-color);
  color: var(--text-color);
  margin: 0;
  padding: 20px;
  min-height: 100vh;
  transition: background-color 0.3s ease;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

a {  
  color: var(--link-color);
  text-decoration: none;
  margin-bottom: 1rem;
}

h1 {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin-bottom: 10px;
  font-size: 6rem; /* Adjust this value as needed */
  font-weight: 700; /* This sets it to bold, adjust as needed */
}

.gradient-text {
  font-family: 'Raleway', sans-serif;
  background-image: linear-gradient(
    90deg,
    #9d00ff, #6e00ff, #c200ff, #b300fc, #d10eff, #e029ff
  );
  background-size: 300% 300%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: gradient-animation 4s ease infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 85% 50%; }
  100% { background-position: 0% 50%; }
}

/* Remove any text shadow */
.light-mode .gradient-text,
.gradient-text {
  text-shadow: none;
}


.status-container {
  text-align: center;
  margin-bottom: 30px;
}

.status-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  animation: pulse 1s infinite;
}

.time {
  font-size: 0.9em;
  color: var(--time-color);
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project {
  background-color: var(--project-bg);
  border: 1px solid var(--project-border);
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  padding-bottom: 40px; /* Increase bottom padding to make room for tags */
  
}

.project:hover {
  transform: translateY(-5px);
}

.project img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 15px;
}

.project-info h2 {
  margin-top: 0;
  font-size: 1.1em;
}

.project-info .date {
  color: var(--time-color);
  font-size: 0.8em;
  margin-bottom: 5px;
}

.project-info p {
  font-size: 0.9em;
  line-height: 1.4;
  margin: 0;
}

footer {
  margin-top: 50px;
  text-align: center;
  font-size: 0.9em;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--pulse-color);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media (max-width: 600px) {
  .project {
    width: 100%;
  }
}

.theme-switch-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6272a4;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider::after {
  color: #333;
  display: block;
  font-family: Verdana, sans-serif;
  font-size: 10px;
  content: '☀️';
  position: absolute;
  right: 8px;
  top: 11px;
}

input:checked + .slider::after {
  content: '🌙';
  left: 8px;
}

footer {
  margin-top: 50px;
  padding: 20px 0;
  text-align: center;
  font-size: 0.9em;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  gap: 10px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 3px;
}

.react-logo {
  width: 25px;
  height: 25px;
  animation: react-spin infinite 3s linear;
}

@keyframes react-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    gap: 20px;
  }
  
  .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.app-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.jellyfish-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.jellyfish {
  position: absolute;
  background: rgba(147, 51, 234, 0.3);
  border-radius: 70% 70% 60% 60%;
  box-shadow: 0 0 20px rgba(147, 51, 234, 0.5);
  filter: blur(5px);
  transform-origin: center center;
}

.jellyfish::before,
.jellyfish::after {
  content: '';
  position: absolute;
  background: rgba(147, 51, 234, 0.2);
  border-radius: 50%;
}

.jellyfish::before {
  width: 90%;
  height: 80%;
  top: 10%;
  left: 5%;
}

.jellyfish::after {
  width: 70%;
  height: 60%;
  top: 30%;
  left: 15%;
}

/* We'll set sizes here, but animations will be set dynamically */
.jellyfish:nth-child(1) { width: 10vmin; height: 12vmin; }
.jellyfish:nth-child(2) { width: 8vmin; height: 9.6vmin; }
.jellyfish:nth-child(3) { width: 12vmin; height: 14.4vmin; }
.jellyfish:nth-child(4) { width: 9vmin; height: 10.8vmin; }
.jellyfish:nth-child(5) { width: 11vmin; height: 13.2vmin; }

@keyframes swim {
  0% {
    transform: translate(calc(-100% - 100px), calc(100% + 100px)) rotate(0deg);
  }
  100% {
    transform: translate(calc(100% + 100px), calc(-100% - 100px)) rotate(360deg);
  }
}
.content-container {
  position: relative;
  z-index: 1;
}

.project {
  /* ... existing styles ... */
  position: relative;  /* Add this to position the tech tags */
}

.tech-tags {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px; /* Add this to ensure tags don't overflow on the right */
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tech-tag {
  font-size: 0.7em;
  padding: 2px 6px;
  border-radius: 3px;
  background-color: var(--tag-bg-color);
  color: var(--tag-text-color);
  margin-bottom: 5px; /* Add some vertical space between wrapped tags */
}

:root {
  /* ... existing variables ... */
  --tag-bg-color: #6272a4;
  --tag-text-color: #f8f8f2;
}

.light-mode {
  /* ... existing variables ... */
  --tag-bg-color: #bd93f9;
  --tag-text-color: #282a36;
}